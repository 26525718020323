.container{
  width: 100%;
  display: flex;
  justify-content: center;
  .banner{
    width: 1300px;
    height: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 5%;
    @media (max-width: 840px) {
      height: auto;
      flex-direction: column-reverse;
    }
    .left{
      width: 447px;
      @media (max-width: 840px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px; 
        h1, p{
          text-align: center;
        }
      }
      h1{
        font-size: 50px;
        margin: 0;
        @media (max-width: 440px) {
          font-size: 35px;
        }
      }
      p{
        margin: 20px 0;
        font-weight: 500;
        font-size: 20px;
        @media (max-width: 440px) {
          font-size: 16px;
        }
      }
      button{
        width: 150px;
        height: 50px;
        background: #ED342C;
        color: white;
        border-radius: 50px;
        border: 0;
        box-shadow: 0px 0px 10px #ED342C;
        margin-top: 20px;
        border: 2px solid #ED342C;
        font-weight: 600;
        cursor: pointer;
        transition: .3s;
        &:hover{
          background: none;
          border: 2px solid #ED342C;
          color: #ED342C;
          box-shadow: none;
        }
      }
    }
    .right{
      img{
        width: 450px;
        @media (max-width: 942px) {
          width: 350px;
        }
        @media (max-width: 440px) {
          width: 250px;
        }
      }
    }
  }
}
.state{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0 100px;
  gap: 50px;
  h2{
    margin: 0;
  }
  .state_btn{
    button{
      width: 200px;
      background-color: green;
      color: white;
      height: 50px;
      border-radius: 50px;
      cursor: pointer;
    }
  }
}
.container{
  width: 100%;
  display: flex;
  justify-content: center;
  .title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      width: 100px;
      height: 3px;
      background: #ED342C;
      @media (max-width: 600px) {
        display: none;
      }
    }
    h1{
      margin: 0 50px;
      font-weight: 400;
      font-size: 35px;
      @media (max-width: 600px) {
        margin: 0;
        font-size: 30px;
      }
    }
  }
}
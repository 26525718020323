.order{
  margin: 0;
  padding: 0;
  .container{
    max-width: 1300px;
    margin: 100px auto 100px;
    .form{
      display: flex;
      flex-direction: column;
      gap: 50px;
      width: 100%;
      label{
        display: flex;
        flex-direction: column;
        margin-left: 50%;
        transform: translateX(-50%);
        gap: 20px;
        .error_inp{
          border-bottom: 1px solid red;
        }
        input{
          height: 20px;
          border-bottom: 1px solid #000;
          font-size: 16px;
          padding: 0;
          background-color: transparent;
        }
        textarea{
          outline: none;
          height: 100px;
          border-bottom: 1px solid #000;
          font-size: 16px;
          padding: 10px;
          background-color: transparent;
        }
      }
    }
    .order_btn{
      display: flex;
      justify-content: center;
      margin-top: 50px;
      button{
        width: 150px;
        height: 50px;
        cursor: pointer;
        border-radius: 50px;
        background-color: #EE3D35;
        color: white;
        transition: .5s;
        &:hover{
          box-shadow: 0 0 10px #EE3D35;
        }
        &:disabled{
          opacity: .7;
        }
      }
    }
    .error_msg{
      display: flex;
      justify-content: center;
      color: red;
      font-weight: 500;
    }
  }
}
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 25px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  .navbar {
    width: 90vw;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 5px 35px;

    @media (max-width: 1024px) {
      width: 80vw;
    }

    @media (max-width: 425px) {
      width: 70vw;
    }

    .logo {
      list-style: none;
      padding: 0;

      li {
        img {
          width: auto;
          height: 50px;
        }
      }
    }

    .list {
      display: flex;
      align-items: center;
      margin-right: -50px;

      @media (max-width: 925px) {
        display: none;
      }

      li {
        list-style: none;
        margin: 0 25px;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
        transition: .2s;

        &:hover {
          color: #ED342C;
          transition: .2s;
        }

        &.active {
          color: #ED342C;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      @media (max-width: 925px) {
        display: none;
      }

      .shopping_cart {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        cursor: pointer;

        li {
          position: relative;

          p {
            position: absolute;
            top: -15px;
            right: -12px;
            padding: 3px 6px;
            border-radius: 50px;
            border: 2px solid #ED342C;
            font-size: 8px;
            font-weight: bold;
            z-index: 10;
          }

          img {
            width: auto;
            height: 20px;
          }
        }
      }

      .phone_number {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        a {
          color: black;

          h3 {
            margin: 0;
            color: black;
            font-weight: 600;
            font-size: 16px;
          }
        }

        p {
          color: #ED342C;
          font-weight: 600;
          margin: 0;
          font-size: 11.5px;
        }
      }
    }

    .shopping_cart_layout {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      display: none;

      @media (max-width: 925px) {
        display: block;
      }
      li {
        position: relative;

        p {
          position: absolute;
          top: -24px;
          right: -12px;
          padding: 3px 6px;
          border-radius: 50px;
          border: 2px solid #ED342C;
          font-size: 8px;
          font-weight: bold;
          z-index: 10;
        }

        img {
          width: auto;
          height: 20px;
        }
      }
    }
  }
}
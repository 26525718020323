.container{
  .stocks{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .my_swiper {
      width: 90%;
      padding: 100px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1214px) {
        margin: 0;
        margin-bottom: 50px;
      }
      @media (max-width:500px) {
        width: 90%;
      }
      //@media (max-width: 1200px) {
      //  width: 1000px;
      //}
      //@media (max-width: 1000px) {
      //  width: 900px;
      //}
      //@media (max-width: 920px) {
      //  width: 850px;
      //}
      //@media (max-width: 870px) {
      //  width: 800px;
      //}
      //@media (max-width: 768px) {
      //  width: 90vw;
      //}
      //@media (max-width: 425px) {
      //  width: 400px;
      //}
      //@media (max-width: 393px) {
      //  width: 350px;
      //}
      //@media (max-width: 360px) {
      //  width: 300px;
      //}
  
      :global(.swiper-button-prev){
        color: white;
        background: #ED342C;
        width: 20px;
        height: 20px;
        padding: 5px;
        border-radius: 50px;
        @media (max-width: 576px) {
          margin-left: 15px;
        }
      }
      :global(.swiper-button-prev:after){
        font-size: 12px;
      }
      :global(.swiper-button-next){
        color: white;
        background: #ED342C;
        width: 20px;
        height: 20px;
        padding: 5px;
        border-radius: 50px;
        @media (max-width: 576px) {
          margin-right: 15px;
        }
      }
      :global(.swiper-button-next:after){
        font-size: 12px;
      }
    }
  }
}
.more{
  display: flex;
  align-items: center;
  flex-direction: column;

  .details{
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
    @media (max-width: 425px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .left{
      @media (max-width: 425px) {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      img{
        width: 330px;
        height: 330px;
        object-fit: cover;
        @media (max-width: 1024px) {
          width: 260px;
          height: 260px;
        }
        @media (max-width: 425px) {
          margin-bottom: 20px;
        }
      }
    }
    .right{
      width: 50%;
      margin-left: 50px;
      @media (max-width: 425px) {
        width: 80%;
      }
      h2{
        margin: 0;
        @media (max-width: 425px) {
          font-size: 16px;
        }
      }
      h1{
        margin: 15px 0;
        @media (max-width: 425px) {
          font-size: 20px;
        }
      }
      button{
        width: 400px;
        height: 54px;
        background: white;
        border: 2px solid black;
        color: black;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 15px;
        cursor: pointer;
        @media (max-width: 768px) {
          width: 300px;
        }

        @media (max-width: 320px) {
          width: 220px;
        }
        &.active{
          background-color: #2c2c2c;
          opacity: 0.5;
          color: white;
        }
      }
      .desc{
        font-size: 16px;
        font-weight: bold;
      }
      p{
        line-height: 30px;
        margin: 0;
      }

      .quantity{
        display: flex;
        align-items: center;
        gap: 50px;
        margin: 30px 0;
        ul{
          display: flex;
          align-items: center;
          margin: 0;
          li{
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px 0 0 50px;
            border-left: 2px solid #2c2c2c;
            border-top: 2px solid #2c2c2c;
            border-bottom: 2px solid #2c2c2c;
            cursor: pointer;
            &:nth-child(3){
              border-radius: 0 50px 50px 0;
              border-left: 0;
              border-right: 2px solid #2c2c2c;
            }
          }
          input{
            width: 40px;
            height: 40px;
            border-top: 2px solid #2c2c2c;
            border-bottom: 2px solid #2c2c2c;
            border-left: 0;
            border-right: 0;
            outline: none;
            text-align: center;
            border-radius: 0;
            background-color: transparent;
            padding: 0;
          }
        }
      }
    }
  }
}

.loader{
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 150px;
}
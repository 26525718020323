.cart {
  position: absolute;
  background-color: #fff;
  width: 600px;
  height: auto;
  right: 47px;
  top: 126px;
  border: 2px solid black;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;

  @media (max-width: 1105px) {
    width: 400px;
  }

  @media (max-width: 470px) {
    right: 0;
    width: 99vw;
  }

  .container {
    padding: 32px 50px;
    position: relative;

    @media (max-width: 1105px) {
      padding: 20PX 30PX;
    }

    .scroll {
      overflow-y: scroll;
      height: 150px;
      padding-right: 10px;
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #EAEAEA;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
      }

    }

    .cross {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;

      svg {
        font-size: 26px;
      }
    }

    .cart_titles {
      display: flex;
      justify-content: space-between;
      padding: 0;
      border-bottom: 1px solid black;
      padding-bottom: 10px;

      li:nth-child(1) {
        margin-right: 115px;

        @media (max-width: 375px) {
          margin-right: 85px;
        }
      }

      li {
        font-weight: bold;
        font-size: inherit;
      }
    }

    .total_price {
      text-align: right;
      margin-top: 10px;
    }

    .pay_btn {
      display: flex;
      justify-content: end;
      margin-top: 20px;

      button {
        width: 150px;
        height: 35px;
        background-color: #fff;
        border: 1px solid #ED342C;
        cursor: pointer;
        border-radius: 10px;

        &:disabled {
          opacity: .7;
          border-color: gray;
        }
      }
    }
  }
}
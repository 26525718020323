.container{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  @media (max-width: 920px) {
    margin-top: 100px;
  }
  .menu{
    width: 1300px;
    display: flex;
    flex-direction: column;
    .cards{
      display: flex;
      flex-wrap: wrap;
      gap: 85px;
      margin-top: 100px;
      padding: 0 35px;
      @media (min-width: 1310px) {
        padding: 0;
      }
      @media (max-width: 1020px) {
        justify-content: center;
      }
    }
  }
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  font-family: montserrat;
}

html{
  scroll-behavior:smooth;
  pointer-events: all;
}

body{
  margin: 0;
  background: #FBFBFB;
  padding-top: 150px ;
  box-sizing: border-box;
}

ul, li{
  list-style: none;
}

input, button{
  border: none;
  outline: none;
}

a{
  color: inherit;
  text-decoration: none;
  font-size: inherit;
}

.btwn_components{
  @media (max-width: 920px) {
    display: none;
  }
}
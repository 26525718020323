.container{
  width: 100%;
  display: flex;
  justify-content: center;
  background: #E9E9E9;
  .footer{
    width: 1300px;
    height: auto;
    display: flex;
    align-items: center;
    padding: 100px 35px;
    @media (max-width: 542px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .left{
      img{
        width: 70px;
      }
    }
    .right{
      margin-left: 50px;
      @media (max-width: 542px) {
        padding: 0;
        margin: 0;
      }
      p{
        display: flex;
        align-items: center;
        @media (max-width: 542px) {
          font-size: 14px;
        }
        @media (max-width: 354px) {
          flex-direction: column;
          align-items: flex-start;
        }
        a{
          margin: 0 10px;
          color: black;
          &:nth-child(1){
            margin-left: 0;
          }
          @media (max-width: 354px) {
            margin: 0;
          }
        }
      }
    }
  }
}
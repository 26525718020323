.container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .categories{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 100px;
    margin-top: 50px;
    padding: 0 35px;
    .category{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .active{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      &::before{
        content: '';
        width: 100%;
        height: 2px;
        background: #ED342C;
        position: absolute;
        left: 0;
        bottom: 5px;
      }
      p{
        color: #ED342C;
      }
    }
  }
  .rolls{
    margin-top: 25px;
    select{
      width: 250px;
      height: 45px;
      padding: 0 15px;
      border-radius: 15px;
      outline: none;
    }
  }
}
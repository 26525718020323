.cart_card{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid black;
      padding-bottom: 10px;
      padding-top: 10px;
      .card_title{
        display: flex;
        gap: 8px;
        img{
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 50%;
        }
        p{
          width: 125px;
          @media (max-width: 395px) {
            width: 100px;
          }
        }
      }
      .card_count{
        display: flex;
        gap: 15px;
        @media (max-width: 375px) {
          gap: 5px;
        }
        span{
          padding: 6px;
          font-size: 12px;
          font-weight: 400;
        }
        svg{
          background-color:#ED342C ;
          color: #fff;
          padding: 6px;
          border-radius: 50%;
          cursor: pointer;
        }
      }
    }